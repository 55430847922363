<template>
  <div>
    <div class="cards">
      <div class="cards-container row d-flex wrap align--start">
        <div
          class="flex xs12 sm6 lg4 xl3 manager-card"
          v-for="(item, i) in contentManagerUserGroupsStatistics.slice().filter(s => s.totalEpackageRetailers !== 0).sort((a,b) =>
            a.quantityCheckedEpackageRetailers / a.totalEpackageRetailers -
            b.quantityCheckedEpackageRetailers / b.totalEpackageRetailers)"
          :key="i"
          @click.prevent="onSelectUserGroup(item)"
        >
          <vac-card
            stripe="info"
            :title="item.name"
          >
            <div class="cards__body">
              <a>
                {{ `${item.quantityCheckedEpackageRetailers}/${item.totalEpackageRetailers} PDPs checked` }}
              </a>
              <div class="cards__percentage">
<!--                <VacChart :data="calcChartData(item)" :options="chartOptions" type="donut"/>-->
                <Doughnut
                  :dataset-id-key="'label'"
                  :chart-options="chartOptions"
                  :chart-data="calcChartData(item)"
                  :css-classes="'doughnut export'"
                />
                <span class="text">{{ calcChartData(item).percentage }}</span>
              </div>
            </div>
          </vac-card>
        </div>
      </div>
    </div>
    <ModalLiveMonitor
      v-if="activeModal"
      :user-group="selectedUserGroup"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import ModalLiveMonitor from "./components/ModalLiveMonitor";
import VacChart from "../../components/VacChart";
import {bus} from "../../app/main";
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)
export default {
  name: 'ContentManager',

  components: {ModalLiveMonitor, Doughnut},

  async created() {
    bus.$emit('set-header-loading', true)
    await this.getContentManagerUserGroups();
    await this.getContentManagerUserGroupsStatistics();
    bus.$emit('set-header-loading', false)
  },

  data() {
    return {
      activeModal: false,
      selectedUserGroup: null,

    };
  },

  computed: {
    ...mapState(['contentManagerUserGroups', 'contentManagerUserGroupsStatistics']),
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '80%',
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          x: {display: false},
        },
      }
    },
  },

  methods: {
    ...mapActions(['getContentManagerUserGroups', 'getContentManagerUserGroupsStatistics']),

    onSelectUserGroup(item) {
      this.activeModal = true;
      this.selectedUserGroup = {
        ...this.contentManagerUserGroups.find(i => i.id === item.id),
        quantityCheckedEpackageRetailers: item.quantityCheckedEpackageRetailers,
        totalEpackageRetailers: item.totalEpackageRetailers
      };
    },

    closeModal() {
      this.activeModal = false;
      this.selectedUserGroup = null;
    },

    calcChartData(item) {
      const percentage = ((item.quantityCheckedEpackageRetailers / item.totalEpackageRetailers || 0) * 100).toFixed(0);
      let color1, color2;
      if (percentage < 50) {
        color1 = '#a50034';
      } else if (percentage >= 50 && percentage < 90) {
        color1 = '#f3972b';
      } else {
        color1 = '#45db54';
      }
      color2 = '#E5E5E5'
      return {
        percentage: percentage + '%',
        datasets: [
          {
            borderColor: "transparent",
            backgroundColor: [color1, color2],
            label: '',
            data: [percentage >= 50 ? percentage : 100 - percentage, percentage >= 50 ? 100 - percentage : percentage],
          },
        ],
        labels: ['', ''],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.doughnut {
  height: 70px;
  width: 60px;
}
.cards {
  &__body {
    display: flex;
    align-items: center;

    a {
      display: block;
      margin-right: 10px;
    }
  }

  &__percentage {
    width: 70px;
    height: 70px;
    z-index: 1;
    position: relative;
    margin-left: auto;

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
    }
  }
}
</style>
