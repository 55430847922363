<template>
  <div class="dashboard-modal-monitor live-monitor-board popup--preview">
    <div class="popup--preview__box">
      <div class="popup--preview__box__header">
        <ModalPreview
          v-if="isPreviewPopup"
          :epack="activeEpack"
          :epackageID="activeEpackageID"
          :retailers="filters.retailers"
          :retailersInfo="retailersInfo"
          @close="closePreviewPopup"/>

        <ModalEpackStat
          v-if="isAnalyticPopup"
          :epackageID="activeEpackageID"
          @close="closeAnalyticPopup"/>

        <Filters
          :loading="loading"
          :on-change="onChangeFilters"
          :use-categories="true"
          :useContentType="true"
          :useDatePicker="false"
          :useStocks="false"
          @filterError="onErrorFilters"
          :user-group="userGroup"
        />
        <div class="popup--preview__box__close" @click="$emit('close-modal',false)">
          <IconClosePopup/>
        </div>
      </div>
      <div class="row row-equal" v-if="!loading">
        <RetailersCoverage :data="retailersCoverage"/>
        <RetailerStatuses :data="retailersStatuses"/>
        <AvrCoverage :data="avrCoverage" :totalAvr="totalAvr"/>
      </div>

      <InfoTiles :data="checksStats" :show-circle="true"/>
      <div class="row table-row">
        <div class="flex md12 xs12">

          <vac-card :title="$t('Live monitor')" class="card-visible overflow--unset">

            <Actions :export-to-excel="exportToExcel"/>

            <div class="row">
              <div class="flex xs12 md3">
                <h1 class="title-table">Total {{ totalCount }}</h1>
              </div>
            </div>

            <div class="row align--center" style="position: relative; z-index: 49;">

              <div class="flex xs12 md3">
                <va-input
                  :placeholder="$t('Search')"
                  :value="searchMonitor"
                  class="input--border"
                  removable
                  @input="onMonitorSearch">
                  <va-icon slot="prepend" name="fa fa-search"/>
                </va-input>
              </div>

              <div
                v-if="!isLoadedByScroll"
                class="flex xs12 md3 offset--md3"
              >
                <va-select

                  v-model="perPageMonitor"
                  :label="$t('tables.perPage')"
                  :options="perPageOptions"
                  noClear
                />
              </div>
            </div>

            <va-tabs v-model="tabValue" style="margin-top: 16px; margin-bottom: 32px; position: relative; z-index: 49;">
              <va-tab
                v-for="title in tabTitles"
                :key="title"
                style="text-transform: uppercase; font-weight: 400; padding: 0.4375rem 1.7rem 0.4375rem 0;">
                {{ title }}
              </va-tab>
            </va-tabs>

            <FiltersByColor
              v-show="tabValue === 0"
              :colors="colors"
              @callback="filterColorCallback"/>

            <FiltersByColor
              v-show="tabValue === 1"
              :colors="stocks"
              @callback="filterStockCallback"/>

            <FiltersByColor
              v-show="tabValue === 2"
              :colors="checkedColor"
              @callback="filterCheckedCallback"/>

            <FiltersByColor
              v-show="tabValue === 3"
              :colors="links"
              @callback="filterLinkCallback"/>

            <FiltersByColor
              v-show="tabValue === 4"
              :colors="correct"
              @callback="filterCorrectCallback"/>

            <div class="flex xs12 md12" v-if="!tableLoading">
              <CustomTable
                :change-check="changeCheck"
                :change-stock="changeStock"
                :change-status-link="changeStatusLink"
                is-admin
                :data="filteredDataMonitor"
                :fields="fieldsMonitor"
                :load-on-scroll="isLoadedByScroll"
                :aliases="aliases"
                :alias-options="aliasOptions"
                :openAnalytic="openAnalytic"
                :openPreview="showPreviewPopup"
                :perPage="parseInt(perPageMonitor)"
                :retailerGroups="customTableModifiedRetailerGroups"
                :retailersInfo="retailersInfo"
                :sku-table="skuTable"
                :update-s-k-u="changeSKU"
                class="table-no-wrap live-monitor"
                @changeLink="changeLink"
              />

            </div>

          </vac-card>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash';
import API from '@/services/API/API';
import ElasticAPI, {getCheckedAverage, getCheckedStats, getStockStats} from '@/services/API/ElasticAPI';
import HelperUser from "@/services/Helpers/HelperUser";
import {clearSymbols, sortAsc, sortObject} from "@/services/Helpers/HelperObject";
import {HelperColor} from "@/services/Helpers/HelperColor";
import {calcLastVisited} from "@/services/Helpers/HelperTime";
import {showToastError, showToastSuccess} from "@/services/Helpers/HelperToast";
import fetchFilters from "@/mixins/fetchFilters";
import InfoTiles from '@/components/stats/InfoTiles'

const ExportJsonExcel = require('js-export-excel');

export default {
  name: 'live-monitor',
  mixins: [fetchFilters],

  components: {
    IconClosePopup: () => import('@/components/ui/icons/IconClosePopup'),
    ModalEpackStat: () => import('@/components/Modal/ModalEpackStat'),
    InfoTiles,
    CustomTable: () => import('@/components/tables/CustomTable'),
    ModalPreview: () => import('@/components/Modal/ModalPreview'),
    RetailersCoverage: () => import('@/components/stats/RetailersCoverage'),
    RetailerStatuses: () => import('@/components/stats/RetailerStatuses'),
    AvrCoverage: () => import('@/components/stats/AvrCoverage'),
    Filters: () => import('@/components/filters/Filters'),
    FiltersByColor: () => import('@/components/filters/FiltersByColor'),
    Actions: () => import('@/components/actions/Actions'),
  },

  data() {
    return {
      tableLoading: false,
      aliases: [],
      aliasOptions: [],
      retailerGroups: {},
      customTableModifiedRetailerGroups: {},
      tabValue: 0,
      tabTitles: ['Filter by color', 'Filter by stock', 'Filter by checked', 'Filter by link', 'Filter by correct'],
      isAnalyticPopup: false,
      activeEpackageID: null,
      monitorData: {},
      activeEpack: {},
      retailersInfo: null,
      skuTable: null,
      activeRetailer: "",
      totalCount: 0,
      totalAvr: 0,
      isOpenModal: true,
      isAdmin: false,
      userInfo: HelperUser.getUserLicensesRetailers(),
      isStockPopup: false,
      isPreviewPopup: false,
      isLoadedByScroll: true,
      totalAvrChecks: 0,
      avrChecks: {
        labels: [],
        datasets: [],
      },
      retailersCoverage: {
        labels: [],
        datasets: [],
      },
      retailersStatuses: {
        labels: [],
        datasets: [],
      },
      avrCoverage: {
        labels: [],
        datasets: [],
      },
      checksStats: [],
      stockInStockStats: {
        datasets: [],
        labels: [],
      },
      stockStatus: "all",
      showModalInfo: false,
      perPageOptions: ['50', '100', '200', '500', '1000'],
      perPageMonitor: '50',
      searchMonitorLicenseName: '',
      searchMonitorRetailerName: '',
      searchMonitor: '',
      searchStatus: '',
      searchStock: '',
      searchChecked: '',
      searchLink: '',
      searchCorrect: '',
      searchValidURL: '',
      selectedLicenses: [],
      defaultLicenses: [],
      defaultRetailers: [],
      defaultData: [],
      dataMonitorStats: [],
      selectedRetailers: [],
      monitorLabels: [],
      monitorSelected: [],
      monitorFields: [],
      blackList: [
        "content.24ttl.stream",
        "panel.24ttl.stream",
        "localhost",
        "my.24ttl.stream",
      ],
      colors: [
        {
          "label": "Rarely",
          "color": "#000000",
          "value": "rarely",
        },
        {
          "label": "Last week",
          "color": "#f3972b",
          "value": "warning",
        },
        {
          "label": "Often",
          "color": "#45db54",
          "value": "success",
        },
        {
          "label": "No visited",
          "color": "#d6dee2",
          "value": "no-visits",
        },
        {
          "label": "No map",
          "color": "#a50034",
          "value": "no-map",
        },
      ],
      stocks: [
        {
          "label": "In stock",
          "color": "#45db54",
          "value": "in-stock",
        },
        {
          "label": "Not in stock",
          "color": "#a50034",
          "value": "not-in-stock",
        },
      ],
      checkedColor: [
        {
          "label": "Checked",
          "icon": "checked",
          "value": true,
        },
        {
          "label": "Not checked",
          "icon": "not-checked",
          "value": false,
        },
      ],
      links: [
        {
          "label": "Link",
          "color": "#45db54",
          "value": true,
        },
        {
          "label": "No link",
          "color": "#a50034",
          "value": false,
        },
      ],
      correct: [
        {
          "label": "Correct",
          "color": "#45db54",
          "value": true,
        },
        {
          "label": "Incorrect",
          "color": "#a50034",
          "value": false,
        },
      ],
    };
  },
  props: ['userGroup'],

  computed: {
    fieldsMonitor() {
      return this.monitorFields;
    },
    filteredDataMonitor() {
      let data = [...this.dataMonitorStats];
      if (this.searchMonitor.length) {
        const searchMonitorQuery = this.searchMonitor.split(',').map(query => query.trim());
        data = data.filter(item => {
          let found = false
          searchMonitorQuery.forEach(searchMonitor => found = found || (item.ean.toLowerCase().includes(searchMonitor.toLowerCase()) ||
            item.mpn.toLowerCase().includes(searchMonitor.toLowerCase()) ||
            item.licenseName.toLowerCase().includes(searchMonitor.toLowerCase()) ||
            item.productName.toLowerCase().includes(searchMonitor.toLowerCase())))
          return found
        });
      }
      if (typeof this.searchLink === "boolean") {
        data = this.getDataByLink(data);
      }

      if (this.searchStatus.length) {
        data = this.getDataByStatus(data);
      }
      if (this.searchStock.length) {
        data = this.getDataByStock(data);
      }
      if (typeof this.searchChecked === "boolean") {
        data = this.getDataByChecked(data);
      }
      if (typeof this.searchCorrect === "boolean") {
        data = this.getDataByCorrect(data);
      }
      this.updateTotalCount(data);
      return data;
    }
  },

  created() {
    this.isAdmin = JSON.parse(localStorage.getItem('ttlUserInfo'))?.roles.includes('ROLE_ADMIN')
    this.loading = !this.isAdmin
    this.getRetailersLinks().then(data => {
      this.retailersInfo = data
    })


    this.getSkuTable().then(data => {
      this.skuTable = data.data
    })
  },

  methods: {
    changeStatusLink({reasonLink, reason, retailer}) {
      this.tableLoading = true
      this.dataMonitorStats = this.dataMonitorStats.map(stat => {
        if (stat[retailer.name]?.epackID === retailer.epackID) {
          stat[retailer.name].reason = reason
          stat[retailer.name].reasonLink = reasonLink
        }
        return stat
      })
      this.tableLoading = false
    },
    async getLicenseAlias() {
      await API.APIGet(`${process.env.VUE_APP_API_URL}/brands/all`, {}, (data) => {
        let selectedBrands = this.$store.state.licenses?.filter(l => {
          return this.filters.licenses?.includes(l.id)
        }).map(l => l.brand.id)
        let res = []
        res = data.filter(d => {
          return selectedBrands?.includes(d.id)
        })
        res.forEach(r => {
          this.aliases.push(...r.aliases)
        })
        this.aliases.forEach(a => {
          this.aliasOptions.push(a.name)
        })
      })
    },
    filterDataForTable(newVal) {
      let data = [...newVal];

      if (this.searchMonitor.length) {
        const searchMonitorQuery = this.searchMonitor.split(',').map(query => query.trim());
        data = data.filter(item => {
          let found = false
          searchMonitorQuery.forEach(searchMonitor => found = found || (item.ean.toLowerCase().includes(searchMonitor.toLowerCase()) ||
            item.mpn.toLowerCase().includes(searchMonitor.toLowerCase()) ||
            item.licenseName.toLowerCase().includes(searchMonitor.toLowerCase()) ||
            item.productName.toLowerCase().includes(searchMonitor.toLowerCase())))
          return found
        });
      }
      if (typeof this.searchLink === "boolean") {
        data = this.getDataByLink(data);
      }

      if (this.searchStatus.length) {
        data = this.getDataByStatus(data);
      }
      if (this.searchStock.length) {
        data = this.getDataByStock(data);
      }
      if (typeof this.searchChecked === "boolean") {
        data = this.getDataByChecked(data);
      }
      if (typeof this.searchCorrect === "boolean") {
        data = this.getDataByCorrect(data);
      }

      this.updateTotalCount(data);
      return data;
    },

    changeStock({inStock, epackID, retailer}) {
      let retailerID = null;

      this.retailersInfo.forEach(retailerInfo => {
        if (clearSymbols(retailerInfo.name.toLowerCase()) === retailer) {
          retailerID = retailerInfo.id;
        }
      });
      if (retailerID) {
        API.APIPut(`${process.env.VUE_APP_API_URL}/epackage/${epackID}/${retailerID}/${inStock ? "in-stock" : "not-in-stock"}`,
          {},
          () => this.updateStockStat(epackID, retailer, inStock),
          error => showToastError(error?.response?.data?.code === 404 ? "Not found" : "Something wrong,<br/>please try later", this.$toast));
      }

    },

    changeSKU({data, epackID, retailer}) {
      let retailerID = null;

      this.retailersInfo.forEach(retailerInfo => {
        if (clearSymbols(retailerInfo.name.toLowerCase()) === retailer) {
          retailerID = retailerInfo.id;
        }
      });

      if (retailerID) {
        API.APIPut(`${process.env.VUE_APP_API_URL}/epackage/${epackID}/${retailerID}/update-sku-and-minisite-template`,
          data,
          () => {
            showToastSuccess('Mapping successfully saved', this.$toast)
          },
          error => showToastError(error?.response?.data?.code === 404 ? "Not found" : "Something wrong,<br/>please try later", this.$toast));
      }
    },

    changeCheck({checked, epackID, retailer}) {
      let retailerID = null;

      this.retailersInfo.forEach(retailerInfo => {
        if (clearSymbols(retailerInfo.name.toLowerCase()) === retailer) {
          retailerID = retailerInfo.id;
        }
      });

      if (retailerID) {
        API.APIPut(`${process.env.VUE_APP_API_URL}/epackage/${epackID}/${retailerID}/${checked ? "check" : "not-check"}`,
          {},
          () => this.updateCheckStat(epackID, retailer, checked),
          error => showToastError(error?.response?.data?.code === 404 ? "Not found" : "Something wrong,<br/>please try later", this.$toast));
      }

    },
    linkWithNoSubdirs(link) {
      if (link) {
        return /^(?:\S+:\/\/)?[^/]+\/?$/g.test(link)
      } else {
        return false
      }
    },

    updateStockStat(epackID = null, retailer = null, inStock = null) {
      let stockInStocks = [];
      let licensesNames = [];
      inStock = inStock ? "in-stock" : "not-in-stock"

      this.dataMonitorStats = this.dataMonitorStats.map(stat => {
        if (stat.epackID === epackID && stat?.[retailer]) {
          stat[retailer].stock = inStock;
          stat[`${retailer}_status_stock`] = inStock;
          Object.keys(this.monitorData).forEach(license => {
            if (clearSymbols(license) === clearSymbols(stat[retailer].licenseName) && this.monitorData[license].data.epackages[epackID]) {
              this.monitorData[license].data.epackages[epackID].retailers[retailer].stock = inStock === "in-stock" ? "In stock" : "Not in stock";
            }
          });
        }

        if (!licensesNames.includes(stat.licenseName)) {
          licensesNames.push(stat.licenseName);
        }

        this.monitorSelectedRetailersNames.forEach((label, index) => {
          let retailerName = clearSymbols(label.toLowerCase());
          if (!stat?.[retailerName]) {
            return false;
          }
          let {stock, status} = stat[retailerName];
          stockInStocks = getStockStats(stockInStocks, stock, status, index);
        });

        return stat;
      });

      this.stockInStockStats = {
        datasets: stockInStocks,
        labels: this.monitorSelectedRetailersNames,
      };
    },

    updateCheckStat(epackID = null, retailer = null, checked = null) {
      let checks = {};
      let licensesNames = [];
      let avrChecks = [];

      this.dataMonitorStats = this.dataMonitorStats.map(stat => {

        if (!licensesNames.includes(stat.licenseName)) {
          licensesNames.push(stat.licenseName);
        }

        if (stat.epackID === epackID && stat?.[retailer]) {
          stat[retailer].check = checked;
          Object.keys(this.monitorData).forEach(license => {
            if (clearSymbols(license) === clearSymbols(stat[retailer].licenseName) && this.monitorData[license].data.epackages[epackID]) {
              this.monitorData[license].data.epackages[epackID].retailers[retailer].check = checked;
            }
          });
        }

        this.monitorSelectedRetailersNames.forEach(label => {
          let retailerName = clearSymbols(label.toLowerCase());

          if (!stat?.[retailerName]) {
            return false;
          }

          let {check, status} = stat[retailerName];
          checks = getCheckedStats(checks, check, status);
          avrChecks = getCheckedAverage(avrChecks, check, status, licensesNames.indexOf(stat.licenseName));
        });

        return stat;
      });

      this.avrChecks = {
        datasets: avrChecks,
        labels: licensesNames,
      };

      if (avrChecks[0]?.data) {
        let avrCheckLength = avrChecks[0]?.data?.length || 1;
        this.totalAvrChecks = Number(+avrChecks[0].data.reduce((acc, current) => {
          acc = +acc;
          current = +current;
          return acc + current;
        }, 0) / avrCheckLength).toFixed(3) || 0;
      }

      this.checksStats = [checks];
    },

    getRetailersLinks() {
      return new Promise(resolve => {
        API.APIGet(`${process.env.VUE_APP_API_URL}/retailers`, {}, retailers => resolve(retailers));
      });
    },

    getSkuTable() {
      let {licenses, retailers} = this.filters
      return new Promise(resolve => {
        API.APIPost(`${process.env.VUE_APP_API_URL}/statistics/get-sku`, {
          retailers,
          licenses,
        }, (stat, response) => {
          resolve(response.data);
        });
      })
    },

    showPreviewPopup(epackageID) {
      this.activeEpackageID = epackageID;
      this.activeEpack = this.dataMonitorStats.find(info => info.epackID === epackageID);
      this.isPreviewPopup = true;
    },

    closePreviewPopup() {
      this.isPreviewPopup = false;
    },

    openAnalytic(epackageID) {
      this.activeEpackageID = epackageID;
      this.isAnalyticPopup = true;
    },

    closeAnalyticPopup() {
      this.isAnalyticPopup = false;
    },

    updateRetailerCharts() {
      const {
        returnData,
        returnDataStatuses,
        returnDataAvr,
        totalAvr,
      } = this.getCoverageByRetailers(this.dataMonitorStats);
      this.retailersCoverage = returnData;
      this.retailersStatuses = returnDataStatuses;
      this.avrCoverage = returnDataAvr;
      this.totalAvr = +totalAvr;
    },

    getCoverageByRetailers(data) {
      const helperColor = new HelperColor();
      let labelStatuses = {
        "success": "Often",
        "warning": "Last week",
        "rarely": "Rarely",
        "no-visits": "No visits",
        "no-map": "No map"
      };

      let statusesData = {};
      let datasetsStatuses = [];

      let datasetsAvr = [];
      let totalAvr = 0;

      let datasets = [];
      let returnData = {
        datasets: [],
        labels: [],
      };

      let retailers = this.selectedRetailers.sort();
      let licenses = [];

      let collectData = {};

      let totalCount = {};
      let noVisits = {};

      let count = 0;

      let dublicateEANRetailers = {};

      data.forEach(info => {
        const {licenseName} = info;

        if (!licenses.includes(licenseName)) {
          licenses.push(licenseName);
        }

        if (!totalCount?.[licenseName]) {
          totalCount[licenseName] = 1;
        } else {
          totalCount[licenseName]++;
        }

        if (!noVisits?.[licenseName]) {
          noVisits[licenseName] = {};
        }

        if (!collectData?.[licenseName]) {
          collectData[licenseName] = {};
        }

        if (!dublicateEANRetailers?.[licenseName]) {
          dublicateEANRetailers[licenseName] = {};
        }

        const props = Object.keys(info);
        props.forEach(prop => {
          const propSplit = prop.split("_");
          let founded;
          let retailer;
          if (propSplit[1] && propSplit[1] === "status") {
            founded = true;
            retailer = propSplit[0];
          } else if (propSplit[2] && propSplit[2] === "status") {
            founded = true;
            retailer = `${propSplit[0]}_${propSplit[1]}`;
          }
          if (founded) {
            const retailerStatus = info[`${retailer}_status`];
            const retailerStock = info[`${retailer}_status_stock`];

            if (!retailerStatus) {
              return false;
            }

            if (!dublicateEANRetailers[licenseName]?.[retailer]) {
              dublicateEANRetailers[licenseName][retailer] = [];
            }

            if (dublicateEANRetailers[licenseName][retailer].includes(info.ean)) {
              return false;
            }

            dublicateEANRetailers[licenseName][retailer].push(info.ean);

            if (!collectData[licenseName]?.[retailer]) {
              collectData[licenseName][retailer] = 0;
            }

            if (!noVisits[licenseName]?.[retailer]) {
              noVisits[licenseName][retailer] = 0;
            }

            if (!statusesData?.[retailer]) {
              statusesData[retailer] = {};
            }


            if (!statusesData[retailer]?.[retailerStatus]) {
              statusesData[retailer][retailerStatus] = 0;
            }

            if (retailerStatus) {
              statusesData[retailer][retailerStatus]++;
            }
            if (retailerStatus === "no-visits" || retailerStatus === "no-map") {

              noVisits[licenseName][retailer]++;

            } else {
              collectData[licenseName][retailer]++;
            }
          }
        });

      });
      statusesData = sortObject(statusesData);

      Object.keys(statusesData).forEach(retailer => {
        const statuses = Object.keys(statusesData[retailer]).sort();
        statuses.forEach(status => {
          const findIndex = datasetsStatuses.findIndex(stat => stat.key === status);
          const findRetailerIndex = this.selectedRetailers.findIndex(label => label === retailer);

          let obj = "";
          if (findIndex !== -1) {

            obj = datasetsStatuses[findIndex];

            if (findRetailerIndex !== -1) {
              obj.data[findRetailerIndex] = statusesData[retailer][status];
            }

          } else {

            const color = this.colors.find(color => color.value === status)?.color || "#000000";

            obj = {
              label: labelStatuses[status],
              key: status,
              retailer: retailer,
              data: [],
              backgroundColor: color,
              borderColor: 'transparent',
            };
            for (let i = 0; i < this.selectedRetailers.length; i++) {
              obj.data.push(0);
            }

            if (findRetailerIndex !== -1) {
              obj.data[findRetailerIndex] = statusesData[retailer][status];
            }

            datasetsStatuses.push(obj);
          }
        });
      });

      const objAvr = {
        label: "",
        data: [],
        backgroundColor: [],
        borderColor: 'transparent',
      };

      let zeroCoverage = [];

      Object.keys(collectData).forEach(license => {
        const noVisitsLicense = noVisits[license];
        let avarage = 0;

        const obj = {
          label: license,
          data: [],
          backgroundColor: helperColor.getColor(),
          borderColor: 'transparent',
        };

        retailers.forEach(retailer => {
          avarage = +(1 - (noVisitsLicense[retailer] / totalCount[license])).toFixed(3);
          obj.data.push(avarage);
        });

        const coverageCount = obj.data.reduce((sum, count) => {
          count = +(count) || 0;
          return sum + count;
        }, 0);

        if (!coverageCount) {
          zeroCoverage.push(license);
        }

        let retailersCount = obj.data.filter(mult => !!mult).length;

        const avr = +((coverageCount / retailersCount).toFixed(2)) || 0;
        totalAvr += avr;

        objAvr.backgroundColor.push(helperColor.getColor());
        objAvr.data.push(avr);

        datasets.push(obj);
      });

      datasetsAvr.push(objAvr);

      totalAvr = (totalAvr / Object.keys(collectData).length).toFixed(2);

      returnData.labels = retailers;
      returnData.datasets = datasets;

      const returnDataStatuses = {
        datasets: datasetsStatuses,
        labels: retailers,
      };

      zeroCoverage.forEach(license => {
        let index = licenses.indexOf(license);
        licenses.splice(index, 1);
        datasetsAvr[0]["data"].splice(index, 1);
      });

      const returnDataAvr = {
        datasets: datasetsAvr,
        labels: licenses,
      };
      return {returnData, returnDataStatuses, returnDataAvr, totalAvr};
    },

    updateTotalCount(value) {
      const values = [];
      this.selectedRetailers.forEach(r => {
        values.push(value.map(v => v[r]).filter(v => !!v).length)
      })
      this.totalCount = Math.max(...values);
    },

    filterColorCallback(value) {
      this.searchStatus = value;
    },

    filterStockCallback(value) {
      this.searchStock = value;
    },

    filterCheckedCallback(value) {
      this.searchChecked = value;
    },

    filterLinkCallback(value) {
      this.searchLink = value;
    },

    filterCorrectCallback(value) {
      this.searchCorrect = value;
    },


    async refreshData() {
      const licenses = this.userInfo.licenses.data.filter(license => this.filters.licenses.includes(license.id));
      const licensesNames = licenses.map(license => license.name);

      this.monitorSelectedRetailers = this.userInfo.retailers.data.filter(retailer => this.filters.retailers.includes(retailer.id));
      this.monitorSelectedRetailersNames = this.monitorSelectedRetailers.map(retailer => clearSymbols(retailer.name.toLowerCase()));
      await this.getLicenseAlias();
      this.stockStatus = this.filters.stockStatus;
      return this.loadData(licenses, licensesNames, this.filters.retailers, this.filters.categories).catch(() => {
        return ''
      });
    },

    loadData(licenses, licensesNames, retailers, categories) {
      const promise = this.collectSKU(licenses);

      promise.then(() => {
        this.setUpLabels(retailers);
        this.updateRetailers(licensesNames, categories);
        this.updateRetailerCharts();
        this.updateCheckStat();
        this.updateStockStat();
      }).catch(error => {
        showToastError('Something went wrong', this.$toast)
        console.error(error)
      });

      return promise
    },

    updateRetailers(licensesNames = null, categories = null) {
      let defaultData = this.defaultData;

      if (categories?.length) {
        defaultData = defaultData.filter(epack => categories.find(category => epack.categories?.find(cat => cat === category)))
      }

      if (licensesNames) {
        defaultData = defaultData.filter(stat => licensesNames.includes(stat.licenseName));
      }
      this.dataMonitorStats = defaultData.map(stat => {
        this.monitorLabels.forEach(retailer => {
          if (!stat?.[retailer]) {
            const {status} = calcLastVisited(null, stat?.[retailer]?.state, stat?.[retailer]?.belongsOzonGroup);
            if (!stat.status.includes(status)) {
              stat.status.push(status);
            }
            stat[`${retailer}_status`] = status;
          }
        });
        return stat;
      });
    },

    getSelectedRetailers(labels, searchBy = "id") {
      let array = [];
      this.userInfo.retailers.data.forEach(retailer => {
        if (labels.includes(clearSymbols(retailer[searchBy].toLowerCase()))) {
          let name = retailer.name.toLowerCase();
          name = this.clearRetailerLabel(name);
          let retailerGroupId = retailer.retailerGroupId
          array.push({name, retailerGroupId});
        }
      });
      let retailers = Object.entries(this.retailerGroups)

      let ungrouped = []

      array = array.filter(item => {
        let res = retailers.find((value) => value[1].find((val) => val['groupId'] === item['retailerGroupId']))
        if (!res) {
          ungrouped.push(item.name)
        }

        return res
      }).map(value => value.name)

      ungrouped.forEach(value => array.push(value))
      return array;
    },

    setUpLabels(labels) {
      this.selectedRetailers = this.getSelectedRetailers(labels);
      this.monitorFields = this.monitorFields.filter(label => label.staticField);
      this.monitorLabels.forEach(retailer => {
        retailer = this.clearRetailerLabel(retailer);
        if (!this.selectedRetailers.includes(retailer) ||
          !Object.entries(this.customTableModifiedRetailerGroups).find(value => clearSymbols(value[0].toLowerCase()) === retailer) &&
          !Object.entries(this.customTableModifiedRetailerGroups).find(value => value[1].find(sub_value => sub_value.key === retailer))) {
          return false;
        }
        const obj = {
          name: retailer,
          title: this.$t(retailer),
          width: '10%',
          dataClass: "--no-padding",
        };
        this.monitorFields.push(obj);
      });
    },

    clearRetailerLabel(retailer) {
      const splitRetailer = retailer.split(".");
      retailer = splitRetailer[0];
      if (splitRetailer[1]) {
        retailer += splitRetailer[1];
      }
      return retailer.toLowerCase();
    },

    filterLink(link) {
      let notInBlackList = true;

      this.blackList.forEach(blackLink => {
        if (link.indexOf(blackLink) !== -1) {
          notInBlackList = false;
        }
      });

      return notInBlackList;
    },

    collectSKU(licenses) {
      this.defaultData = [];
      this.checksStats = [];
      this.avrChecks = {
        datasets: [],
        labels: [],
      };

      this.totalAvrChecks = 0;
      this.stockInStockStats = {
        datasets: [],
        labels: [],
      };

      const promises = [];
      let licensesNames = licenses.map(license => license.name);
      let returnData = {
        checks: {},
        stockInStocks: [],
        avrChecks: [],
      };

      this.retailerGroups = {}
      this.customTableModifiedRetailerGroups = {}

      licenses.forEach((license, licenseIndex) => {
        if (this.selectedLicenses.includes(license.name) && this.monitorData[license.name]) {
          returnData = this.collectMonitorData(this.monitorData[license.name], returnData, licensesNames, licenseIndex);
        } else {
          this.selectedLicenses.push(license.name);
          const promise = ElasticAPI.getLiveMonitor(license.id, license.name);
          promises.push(promise);

          promise.then(result => {
            result = this.convertToLiveMonitorData(result)
            this.monitorData[license.name] = result;
            returnData = this.collectMonitorData(result, returnData, licensesNames, licenseIndex);
          });
        }
      });

      return Promise.all(promises);
    },

    async setRetailersGroups(retailers) {
      retailers.forEach(retailer => {
        Object.entries(retailer.retailers).forEach((entry) => {
          const [key, value] = entry
          if (!this.filters.retailers.includes(value.id)) {
            return
          }
          let retailerGroupName
          let groupId = value.group?.id
          if (value.group?.id) {
            retailerGroupName = this.findRetailerGroupName(value.group.id)
          }
          if (retailerGroupName) {
            if (this.retailerGroups[retailerGroupName]) {
              if (this.retailerGroups[retailerGroupName].filter(value => value.key === key).length === 0) {
                this.customTableModifiedRetailerGroups[retailerGroupName].push({key, id: value.id})
                this.retailerGroups[retailerGroupName].push({key, groupId})
              }
            } else {
              this.$set(this.retailerGroups, retailerGroupName, [{key, groupId}])
              this.$set(this.customTableModifiedRetailerGroups, retailerGroupName, [{key, id: value.id}])
            }
          } else {
            this.$set(this.retailerGroups, key, [])
            this.$set(this.customTableModifiedRetailerGroups, key, [{key, id: value.id}])
          }
        })
      })

      let retailersAll = null;

      await new Promise(resolve => API.APIGet(`${process.env.VUE_APP_API_URL}/retailers`, {}, (response) => {
        retailersAll = response
        resolve(true)
      }))

      Array.from(Object.entries(this.customTableModifiedRetailerGroups)).forEach(group => {
        group[1].forEach((retailer, key) => {
          let retailerFound = retailersAll?.find(ret => ret.id === retailer.id)
          if (retailerFound) {
            this.customTableModifiedRetailerGroups[group[0]][key]['mappingBy'] = retailerFound.mappingBy;
            this.customTableModifiedRetailerGroups[group[0]][key]['statisticWorks'] = retailerFound.statisticWorks;
            this.customTableModifiedRetailerGroups[group[0]][key]['hideContent'] = retailerFound.hideContent;
          }
        })
      })
    },
    findRetailerGroupName(retailerGroupId) {
      if (this.$store.state.retailerGroups === null)
        return null
      return this.$store.state.retailerGroups.find(retailerGroup => retailerGroup.id === retailerGroupId)?.name
    },

    convertToLiveMonitorData(result) {
      let convertedData = {
        licenseName: result.licenseName,
        data: {
          epackages: {}
        }
      }
      Object.keys(result.data).forEach(r => {
        let convertedRetailers = {}
        Object.keys(result.data[r].retailers).forEach(rit => {
          const retailerKey = result.data[r].retailers[rit].name.toLowerCase()
          convertedRetailers[retailerKey] = result.data[r].retailers[rit]
          if (result.data[r].retailers[rit].state === 'data-exist') {
            convertedRetailers[retailerKey].state = 'success'
          }
          convertedRetailers[retailerKey].link_to_product = result.data[r].retailers[rit].linkToProduct
          convertedRetailers[retailerKey].last_visited = result.data[r].retailers[rit].lastVisited
        })
        convertedData.data.epackages[result.data[r].mpn] = result.data[r]
        convertedData.data.epackages[result.data[r].mpn].retailers = convertedRetailers
        convertedData.data.epackages[result.data[r].mpn].product_name = result.data[r].name
      })
      return convertedData
    },

    collectMonitorData(result, returnData, licensesNames, licenseIndex) {
      this.setRetailersGroups(Object.values(result.data.epackages))
      const userRetailers = this.userInfo.retailers.names.map(name => this.clearRetailerLabel(name));

      let {checks, stockInStocks, avrChecks} = returnData;

      const {data, licenseName} = result;

      if (typeof data.epackages !== "object") {
        return false;
      }

      Object.keys(data.epackages).forEach(epackID => {

        const epack = data.epackages[epackID];
        const {id, active, retailers, categories, product_name, mpn, ean} = epack;

        epackID = id;

        const field = {
          epackID,
          active,
          licenseName,
          mpn,
          ean,
          productName: product_name,
          skus: [],
          sku: "",
          status: [],
          statusStock: [],
          categories: categories,
        };

        let retailersKeys = sortAsc(Object.keys(retailers));

        retailersKeys.forEach((retailer, index) => {
          const obj = retailers?.[retailer];

          if (!obj) {
            return false;
          }
          let {check} = obj;

          // if (!obj || (!obj.skuId && !obj.sku)) {return false;}

          retailer = this.clearRetailerLabel(retailer);

          if (!this.monitorLabels.includes(retailer)
            && retailer !== 'sku'
            && userRetailers.includes(retailer.toLowerCase())) {
            this.monitorLabels.push(retailer);
          }

          let linkToProduct = "";
          let last_visited = "";
          let stock = obj?.stock || "";
          let sku = obj.sku;

          if (typeof obj === "object") {
            let {link_to_product} = obj;
            link_to_product = link_to_product || "";
            link_to_product = link_to_product === "-" ? "" : link_to_product;
            link_to_product = link_to_product.split('?utm')?.[0];
            last_visited = obj.last_visited;
            linkToProduct = this.filterLink(link_to_product) ? link_to_product : "";
            stock = obj?.stock;
          }
          let {status, text, humanDate, noLink} = calcLastVisited(last_visited, obj?.state, linkToProduct, obj?.belongsOzonGroup);

          obj.stock = status === "success" ? "in-stock" : stock;

          checks = getCheckedStats(checks, check, status);
          stockInStocks = getStockStats(stockInStocks, stock, status, index);
          avrChecks = getCheckedAverage(avrChecks, check, status, licenseIndex);

          stock = stock === "In stock" ? "in-stock" : stock === "Not in stock" ? "not-in-stock" : "";

          const excel = {
            lastVisited: last_visited,
            link: linkToProduct,
            status,
            stock,
          };

          field[retailer] = {
            ...obj,
            text,
            humanDate,
            link: linkToProduct,
            lastVisited: last_visited,
            stock,
            status,
            name: retailer,
            ean,
            mpn,
            noLink,
            productName: product_name,
            epackID,
            licenseName,
          };
          field[`${retailer}_status`] = status;
          field[`${retailer}_status_stock`] = stock;
          field[`${retailer}_excel`] = excel;

          if (sku && !field.skus.includes(sku)) {
            field.skus.push(sku);
            field.sku += field.sku.length ? ", " + sku : sku;
          }

          if (!field.status.includes(status)) {
            field.status.push(status);
          }

          if (!field.statusStock.includes(stock)) {
            field.statusStock.push(stock);
          }

        });

        this.defaultData.push(field);

        this.checksStats = [checks];

        this.avrChecks = {
          datasets: avrChecks,
          labels: licensesNames,
        };

        if (avrChecks[0]?.data) {
          let avrCheckLength = avrChecks[0]?.data?.length || 1;

          this.totalAvrChecks = Number(+avrChecks[0].data.reduce((acc, current) => {
            acc = +acc;
            current = +current;
            return acc + current;
          }, 0) / avrCheckLength).toFixed(3) || 0;

          this.stockInStockStats = {
            datasets: stockInStocks,
            labels: this.monitorLabels,
          };
        }

      });

      return {checks, stockInStocks, avrChecks};

    },

    getDataByStatus(data) {
      let accessEans = [];
      return data.filter(info => {

        const retailers = this.getSelectedRetailers(this.selectedRetailers, "name");
        let hasAccess = false;

        if (!accessEans.includes(info.ean)) {

          retailers.forEach(retailer => {

            retailer = info?.[retailer];

            if (!retailer) {
              return false;
            }
            if (this.searchStatus !== 'success') {
              if (retailer.status === this.searchStatus) {
                hasAccess = true;
                accessEans.push(info.ean);
              }
            } else {
              if (retailer.status === 'success' || retailer.status === 'rarely' || retailer.status === 'warning') {
                hasAccess = true;
                accessEans.push(info.ean);
              }
            }
          });
        }

        return hasAccess;
      });
    },

    getDataByChecked(data) {
      let accessEans = [];
      const retailers = this.getSelectedRetailers(this.selectedRetailers, "name");

      return data.filter(info => {
        let hasAccess = false;

        if (!accessEans.includes(info.ean)) {

          retailers.forEach(retailer => {
            retailer = info?.[retailer];

            if (!retailer) {
              return false;
            }

            let {check, status} = retailer;

            if (check === this.searchChecked && status !== "no-map") {
              hasAccess = true;
              accessEans.push(info.ean);
            }
          });

        }

        return hasAccess;
      });
    },

    getDataByStock(data) {
      let accessEans = [];
      return data.filter(info => {
        const retailers = this.getSelectedRetailers(this.selectedRetailers, "name");
        let hasAccess = false;

        if (!accessEans.includes(info.ean)) {

          retailers.forEach(retailer => {
            retailer = info?.[retailer];
            if (!retailer) {
              return false;
            }

            let {status, stock} = retailer;

            stock = status === "success" ? "in-stock" : stock;

            if (status !== "no-map" && stock === this.searchStock) {
              hasAccess = true;
              accessEans.push(info.ean);
            }
          });

        }

        return hasAccess;
      });
    },

    getDataByLink(data) {
      let accessEans = [];
      const retailers = this.getSelectedRetailers(this.selectedRetailers, "name");

      return data.filter(info => {
        let hasAccess = false;

        if (!accessEans.includes(info.ean)) {

          retailers.forEach(retailer => {
            let infoRetailer = info?.[retailer];

            if (!infoRetailer) {
              return false;
            }

            let {link_to_product, status} = infoRetailer;
            if ((link_to_product && this.linkWithNoSubdirs(link_to_product) || link_to_product === null || link_to_product === '' || link_to_product === '-' || link_to_product === undefined) !== this.searchLink && status !== 'no-map') {
              hasAccess = true;
              accessEans.push(info.ean);
            }

          });

        }

        return hasAccess;
      });
    },

    getDataByCorrect(data) {
      let accessEans = [];
      const retailers = this.getSelectedRetailers(this.selectedRetailers, "name");

      return data.filter(info => {
        let hasAccess = false;

        if (!accessEans.includes(info.ean)) {

          retailers.forEach(retailer => {
            let infoRetailer = info?.[retailer];

            if (!infoRetailer) {
              return false;
            }

            let {correct} = infoRetailer;


            if (correct === this.searchCorrect) {
              hasAccess = true;
              accessEans.push(info.ean);
            }
          });

        }

        return hasAccess;
      })
    },

    async changeLink(name, epackageId, link) {
      this.tableLoading = true
      this.dataMonitorStats.forEach((data, index) => {
        if (data?.epackID === epackageId && this.dataMonitorStats[index]?.[name]) {
          this.$set(this.dataMonitorStats[index][name], 'link', link)
          this.$set(this.dataMonitorStats[index][name], 'link_to_product', link)
          this.$set(this.dataMonitorStats[index][name], 'noLink',
            (this.dataMonitorStats[index][name].link_to_product && this.linkWithNoSubdirs(this.dataMonitorStats[index][name].link_to_product) || this.dataMonitorStats[index][name].link_to_product === ''))
        }
      })
      this.tableLoading = false
    },

    exportToExcel() {
      const labels = ['License', 'Epackage ID', 'Product Name', 'EAN', 'MPN'];
      const newLabels = [];
      let data = this.dataMonitorStats;

      this.selectedRetailers.forEach(label => {
        if (!this.monitorLabels.includes(label)) {
          return false;
        }
        newLabels.push(`${label} Link`);
        newLabels.push(`${label} Last Visited`);
        newLabels.push(`${label} Status`);
        newLabels.push(`${label} Stock`);
        newLabels.push(`${label} Checked`);
      });

      labels.push(...newLabels);

      if (this.searchStatus.length) {
        data = this.getDataByStatus(data);
      }

      if (this.searchStock.length) {
        data = this.getDataByStock(data);
      }

      if (typeof this.searchChecked === "boolean") {
        data = this.getDataByChecked(data);
      }

      if (typeof this.searchLink === "boolean") {
        data = this.getDataByLink(data);
      }

      if (typeof this.searchCorrect === "boolean") {
        data = this.getDataByCorrect(data);
      }

      const dataSheet = this.collectData(this.monitorLabels, data);

      const columnWidths = [];
      labels.forEach((label, index) => {
        const width = index > 5 ? 10 : 15;
        columnWidths.push(width);
      });

      const option = {
        fileName: 'Live Monitor',
        datas: [
          {
            sheetData: dataSheet,
            sheetName: 'sheet',
            sheetFilter: labels,
            sheetHeader: labels,
            columnWidths: columnWidths,
          },
        ],
      };

      const toExcel = new ExportJsonExcel(option); // new
      toExcel.saveExcel();
    },

    collectData(labels, datasets) {
      const dataSheet = [];

      const stocks = {
        "in-stock": "In stock",
        "not-in-stock": "Not in stock",
      };

      datasets.forEach(data => {
        const obj = {
          License: data.licenseName,
          'Product Name': data.productName,
          'Epackage ID': data.epackID,
          EAN: data.ean,
          MPN: data.mpn,
        };

        labels.forEach(label => {
          if (data?.[label]) {
            const info = data?.[label];

            if (!info) {
              return false;
            }

            let {stock, text, humanDate, check} = info;
            humanDate = Array.isArray(humanDate) ? `${humanDate[0]} ${humanDate[1]}` : humanDate;

            obj[`${label} Link`] = info?.link || "None";
            obj[`${label} Last Visited`] = humanDate;
            obj[`${label} Status`] = text;
            obj[`${label} Stock`] = stocks[stock];
            obj[`${label} Checked`] = check ? "Checked" : "Not checked";
          }
        });

        dataSheet.push(obj);
      });

      return dataSheet;
    },

    onMonitorSearch: debounce(function (value) {
      this.searchMonitor = value.trim();
    }, 400),
  },

};
</script>

<style lang="scss">

.vac-card {
  min-height: auto !important;
}

.dashboard-modal-monitor {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  background-color: #f1f4f8 !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 20px;

  .popup--preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    &__box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #eeeeee;
      padding: 10px;
      height: 100%;
      width: 100%;
      max-width: 100%;

      .select-block {
        margin-left: 24px;
        width: 40%;

        &__title {
          margin-bottom: 12px;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }

        &__sizes {
          font-size: 36px;

          i {
            margin-right: 16px;
            cursor: pointer;
            transition: 0.26s;
            color: #000000;

            &:last-child {
              margin-right: 0;
            }

            &:hover,
            &.active {
              color: #4598db;
            }

            &.active {
              cursor: default;
            }
          }
        }
      }

      &__close {
        border: 1px solid #e5e8ea;
        border-radius: 3px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
      }

      &__header,
      &__rich {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 20px;
      }

      &__header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
      }

      &__rich {
        padding: 0 0 16px 0;
        height: calc(100% - 124px);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 1px solid #e5e8ea;

        .iframe {
          width: 100%;
          height: auto;
          margin: 16px auto 0;
          transition: 0.3s;
          border: 1px solid #e5e8ea;
          overflow-y: scroll;
          overflow-x: hidden;
        }
      }

      .search-fields {
        border-top: 1px solid #d6dee2;
        padding-top: 32px;

        input {
          width: 100%;
        }
      }

      &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(#000000, 0.6);
  }

  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 6px;
    padding: 40px;
    //max-width: 640px;
    width: 100%;

    &__close {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 999999;
    }

    .live-monitor-board {
      overflow-y: auto !important;
    }

    .search-fields {
      border-top: 1px solid #d6dee2;
      margin-top: 32px;
      padding-top: 32px;

      input {
        width: 100%;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .square-toggle {
      margin-top: 32px;
    }
  }
}

</style>
